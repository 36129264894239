var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "fe-overlay bg-white"
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center align-items-center" },
            [
              _c("b-spinner", {
                attrs: { variant: "primary", label: "Loading" }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ]
        },
        [
          _c("b-row", { staticClass: "justify-content-lg-center" }, [
            _c("div", { staticClass: "fe-content" }, [_c("router-view")], 1)
          ]),
          _vm._v(" "),
          _c("notifications", {
            attrs: { group: "tips", position: "top center" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }