import Mock from 'mockjs'

import UserApi from './user'
import DashboardApi from './dashboard'
import UploadApi from './upload'
import SettingApi from './setting'

// User
Mock.mock(/\/api\/v1\/authenticate/, 'post', UserApi.login)
Mock.mock(/\/api\/v1\/user\/info\.*/, 'get', UserApi.getUserInfo)
Mock.mock(/\/api\/v1\/auth\/logout/, 'post', UserApi.logout)

// User Manager
Mock.mock(/\/api\/v1\/user\/list/, 'get', UserApi.getUserList)
Mock.mock(/\/api\/v1\/user\/create/, 'post', UserApi.createUser)
Mock.mock(/\/api\/v1\/user\/[0-9]+\/update/, 'post', UserApi.updateUser)
Mock.mock(/\/api\/v1\/user\/[0-9]+\/delete/, 'post', UserApi.deleteUser)

// Profile
Mock.mock(/\/api\/v1\/user\/get_profile/, 'get', UserApi.getProfile)
Mock.mock(/\/api\/v1\/user\/update_profile/, 'post', UserApi.updateProfile)
Mock.mock(/\/api\/v1\/user\/update_password/, 'post', UserApi.updatePassword)

// Setting
Mock.mock(/\/api\/v1\/setting\/info/, 'get', SettingApi.getSetting)
Mock.mock(/\/api\/v1\/setting\/update/, 'post', SettingApi.updateSetting)

// Dashboard
Mock.mock(/\/api\/v1\/dashboard\/info\.*/, 'get', DashboardApi.getDashboardInfo)

// Upload
Mock.mock(/\/api\/v1\/upload/, 'post', UploadApi.upload)

export default Mock
