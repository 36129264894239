var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "layout-footer footer", class: _vm.getLayoutFooterBg() },
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3"
        },
        [
          _c("div", { staticClass: "pt-3" }, [
            _c("span", { staticClass: "footer-text font-weight-bolder" }, [
              _vm._v(_vm._s(_vm.$t(_vm.websiteTitle)))
            ]),
            _vm._v(" ©\n    ")
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "footer-link pt-3",
                attrs: { href: "javascript:void(0)" }
              },
              [_vm._v(_vm._s(_vm.$t("TITLE_ABOUT_US")))]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }