import { login, logout, gitlabLoginCallback } from '@/api/auth'
import { getUserInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

export const types = {
  SET_TOKEN: 'set_token',
  SET_NAME: 'set_name',
  SET_ROLES: 'set_roles'
}

const state = {
  token: getToken(),
  name: 'Marquis',
  roles: []
}
const mutations = {
  [types.SET_TOKEN] (state, token) {
    state.token = token
  },
  [types.SET_NAME] (state, name) {
    state.name = name
  },
  [types.SET_ROLES] (state, roles) {
    state.roles = (!roles || (roles && roles.length < 1) ? roles.concat(window.siteGlobalConfig.roles) : roles)
  }
}
const actions = {
  // 登录
  login ({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password, rememberMe: true }).then(response => {
        if (response.data.code !== -1) {
          const data = response.data
          commit(types.SET_TOKEN, data.id_token) // 存Token
          setToken(data.id_token)
          resolve()
        } else {
          reject(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  gitlabLoginCallback ({ commit }, data) {
    return new Promise((resolve, reject) => {
      gitlabLoginCallback(data).then(response => {
        const data = response.data
        commit(types.SET_TOKEN, data.id_token) // 存Token
        setToken(data.id_token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取当前登录的用户信息
  getInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(response => {
        const data = response.data
        const { authorities, login } = data

        commit(types.SET_NAME, login)
        commit(types.SET_ROLES, authorities) // 存权限
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 登出
  logout ({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit(types.SET_TOKEN, '')
      commit(types.SET_ROLES, [])
      removeToken()
      resetRouter()
      resolve()
      // logout(state.token).then(() => {
      //   commit(types.SET_TOKEN, '')
      //   commit(types.SET_ROLES, [])
      //   removeToken()
      //   resetRouter()
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },
  // 清空Token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit(types.SET_TOKEN, '')
      commit(types.SET_ROLES, [])
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
