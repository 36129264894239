/* eslint-disable space-before-function-paren */
import request from '@/utils/frontend/request'

/**
 * 获取当前用户信息
 * @param {*} params
 */
export function getFormInfo(params = {}) {
  return request({
    url: '/public/form',
    method: 'get',
    params: params
  })
}

/**
 * 获取ico
 * @param {*} params
 */
export function getFormIco(params = {}) {
  return request({
    url: '/public/form/meta',
    method: 'get',
    params: params
  })
}

/**
 * 获取当前Guest信息通过GuestId
 * @param {*} params
 */
export function getGuestInfoById(id = 0, params = {}) {
  return request({
    url: `/public/guests/${id}`,
    method: 'get',
    params: params
  })
}

/**
 * 更新管理員用戶
 * @param {*} params
 */
export function updateForm(params = {}, data = {}) {
  return request({
    'url': `/public/register`,
    params,
    'method': 'post',
    data
  })
}

/**
 * 發送信息獲取驗證碼
 * @param {*} params
 */
export function sendMessage(params = {}) {
  return request({
    url: `/public/form/sendMessage`,
    method: 'get',
    params: params
  })
}

/**
 * 手机recaptcha验证
 * @param {*} params
 */
export function reCaptcha(params = {}) {
  return request({
    url: `/public/recaptcha/verify`,
    method: 'get',
    params: params
  })
}

/**
 * 判斷驗證碼是否正確
 * @param {*} params
 */
export function validateMessage(params = {}, data = {}) {
  return request({
    url: `/public/form/validateMessage`,
    method: 'post',
    params: params,
    data
  })
}

/**
 * 上傳附件
 * @param {*} params
 */
export function uploadFile(params = {}, data = {}) {
  return request({
    url: `/public/form/uploadFile`,
    method: 'post',
    params: params,
    data
  })
}

/**
 * 獲取驗證碼
 * @param {*} params
 */
export function getCaptcha(params = {}) {
  return request({
    url: `/public/recaptcha/captcha`,
    method: 'get',
    params: params
  })
}

/**
 * 驗證
 * @param {*} params
 */
export function checkVerificationCode(params = {}) {
  return request({
    url: `/public/recaptcha/verify`,
    method: 'post',
    params: params
  })
}

/**
 * 獲取活動信息
 * @param {*} params
 */
export function getDssoptSignupQuota(params = {}) {
  return request({
    url: `/public/dssopt-signup-quota`,
    method: 'get',
    params: params
  })
}

/**
 * 解釋token
 * @param {*} params
 */
export function getParseToken(params = {}) {
  return request({
    url: `public/parse-token`,
    method: 'get',
    params: params
  })
}

/**
 * 查詢手機是否登記
 * @param {*} params
 */
export function getToken(params = {}) {
  return request({
    url: `public/getToken`,
    method: 'get',
    params: params
  })
}

/**
 * 獲取preview數據
 * @param {*} params
 */
export function getPreview(params = {}) {
  return request({
    url: `public/preview`,
    method: 'get',
    params: params
  })
}

export function getSearch(params = {}, datas = {}) {
  return request({
    url: `public/info/query`,
    method: 'post',
    params: params,
    data: datas
  })
}
