var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "layout-navbar align-items-lg-center container-p-x",
      attrs: { toggleable: "lg", variant: _vm.getLayoutNavbarBg() }
    },
    [
      _c(
        "b-navbar-brand",
        {
          staticClass: "app-brand demo d-lg-none py-0 mr-4",
          attrs: { to: _vm.routerPrefix.admin }
        },
        [
          _c(
            "span",
            { staticClass: "app-brand-text demo font-weight-normal ml-2" },
            [_vm._v("\n      " + _vm._s(_vm.$t(_vm.websiteTitle)) + "\n    ")]
          )
        ]
      ),
      _vm._v(" "),
      _vm.sidenavToggle
        ? _c(
            "b-navbar-nav",
            {
              staticClass:
                "layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto"
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-item nav-link px-0 mr-lg-4",
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.toggleSidenav }
                },
                [
                  _c("i", {
                    staticClass: "ion ion-md-menu text-large align-middle"
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "app-layout-navbar" } }),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "app-layout-navbar" } },
        [
          _c("hr", { staticClass: "d-lg-none w-100 my-2" }),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "align-items-lg-center ml-auto" },
            [
              _c(
                "b-dd",
                {
                  staticClass: "dropdown-toggle-hide-arrow",
                  attrs: { variant: "link", size: "md" }
                },
                [
                  _c("template", { slot: "text" }, [
                    _c("i", {
                      staticClass: "fas fa-language text-xlarge text-white"
                    })
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.langs, function(lang, index) {
                    return _c(
                      "b-dd-item",
                      {
                        key: index,
                        attrs: { disabled: _vm.$i18n.locale === lang.value },
                        on: {
                          click: function($event) {
                            return _vm.handleChangeLang(lang.value)
                          }
                        }
                      },
                      [_vm._v(_vm._s(lang.label))]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "demo-navbar-user",
                  attrs: { right: !_vm.isRTL }
                },
                [
                  _c("template", { slot: "button-content" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-inline-flex flex-lg-row-reverse align-items-center align-middle"
                      },
                      [
                        _c("img", {
                          staticClass: "d-block ui-w-30 rounded-circle",
                          attrs: {
                            src: _vm.baseUrl + "img/avatars/1.png",
                            alt: ""
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "px-1 mr-lg-2 ml-2 ml-lg-0" },
                          [_vm._v(_vm._s(_vm.name))]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-dd-item", { on: { click: _vm.handleProfile } }, [
                    _c("i", {
                      staticClass: "ion ion-ios-person text-lightest"
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("TITLE_PROFILE")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-dd-divider"),
                  _vm._v(" "),
                  _c("b-dd-item", { on: { click: _vm.handleLogout } }, [
                    _c("i", { staticClass: "ion ion-ios-log-out text-danger" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("TITLE_LOGOUT")) +
                        "\n        "
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }