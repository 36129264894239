var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden &&
    _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
    (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
    !_vm.item.alwaysShow
    ? _c(
        "sidenav-router-link",
        {
          attrs: {
            icon:
              _vm.onlyOneChild.meta.icon ||
              (_vm.item.meta && _vm.item.meta.icon),
            active: _vm.isMenuActive(
              this.onlyOne ? _vm.onlyOneChild.path : _vm.item.path
            ),
            open: _vm.isMenuOpen(
              this.onlyOne ? _vm.onlyOneChild.path : _vm.item.path
            ),
            to: _vm.resolvePath(_vm.onlyOneChild.path),
            exact: true
          }
        },
        [_vm._v(_vm._s(_vm.$t(_vm.onlyOneChild.meta.title)))]
      )
    : !_vm.item.hidden
    ? _c(
        "sidenav-menu",
        {
          attrs: {
            icon: _vm.item.meta && _vm.item.meta.icon,
            active: _vm.isMenuActive(_vm.item.path),
            open: _vm.isMenuOpen(_vm.item.path)
          }
        },
        [
          _c("template", { slot: "link-text" }, [
            _vm._v(_vm._s(_vm.$t(_vm.item.meta.title)))
          ]),
          _vm._v(" "),
          _vm._l(_vm.item.children, function(child) {
            return _c("layout-sidenav-item", {
              key: child.path,
              attrs: { item: child, "base-path": _vm.resolvePath(child.path) }
            })
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }