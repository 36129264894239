var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidenav",
    { class: _vm.curClasses, attrs: { orientation: _vm.orientation } },
    [
      _vm.orientation !== "horizontal"
        ? _c(
            "div",
            { staticClass: "app-brand demo" },
            [
              _c("span", { staticClass: "app-brand-logo demo bg-primary" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      viewBox: "0 0 148 80",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink"
                    }
                  },
                  [
                    _c(
                      "defs",
                      [
                        _c(
                          "linearGradient",
                          {
                            attrs: {
                              id: "a",
                              x1: "46.49",
                              x2: "62.46",
                              y1: "53.39",
                              y2: "48.2",
                              gradientUnits: "userSpaceOnUse"
                            }
                          },
                          [
                            _c("stop", {
                              attrs: { "stop-opacity": ".25", offset: "0" }
                            }),
                            _vm._v(" "),
                            _c("stop", {
                              attrs: { "stop-opacity": ".1", offset: ".3" }
                            }),
                            _vm._v(" "),
                            _c("stop", {
                              attrs: { "stop-opacity": "0", offset: ".9" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("linearGradient", {
                          attrs: {
                            id: "e",
                            x1: "76.9",
                            x2: "92.64",
                            y1: "26.38",
                            y2: "31.49",
                            "xlink:href": "#a"
                          }
                        }),
                        _vm._v(" "),
                        _c("linearGradient", {
                          attrs: {
                            id: "d",
                            x1: "107.12",
                            x2: "122.74",
                            y1: "53.41",
                            y2: "48.33",
                            "xlink:href": "#a"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("path", {
                      staticStyle: { fill: "#fff" },
                      attrs: {
                        transform: "translate(-.1)",
                        d:
                          "M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        transform: "translate(-.1)",
                        d:
                          "M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z",
                        fill: "url(#a)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        transform: "translate(-.1)",
                        d:
                          "M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z",
                        fill: "url(#e)"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        transform: "translate(-.1)",
                        d:
                          "M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z",
                        fill: "url(#d)"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "app-brand-text demo sidenav-text font-weight-normal ml-2",
                  attrs: { to: _vm.routerPrefix.admin }
                },
                [_vm._v(_vm._s(_vm.$t(_vm.websiteTitle)))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "layout-sidenav-toggle sidenav-link text-large ml-auto",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.toggleSidenav()
                    }
                  }
                },
                [_c("i", { staticClass: "ion ion-md-menu align-middle" })]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orientation !== "horizontal"
        ? _c("div", { staticClass: "sidenav-divider mt-0" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sidenav-inner",
          class: { "py-1": _vm.orientation !== "horizontal" }
        },
        _vm._l(_vm.permission_routes, function(route, index) {
          return _c("layout-sidenav-item", {
            key: "" + route.path + index,
            attrs: { item: route, "base-path": route.path }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }