var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-wrapper layout-2" }, [
    _c(
      "div",
      { staticClass: "layout-inner" },
      [
        _c("app-layout-sidenav"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "layout-container" },
          [
            _c("app-layout-navbar"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "layout-content" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "router-transitions container-fluid flex-grow-1 container-p-y"
                  },
                  [_c("router-view", { key: _vm.$route.fullPath })],
                  1
                ),
                _vm._v(" "),
                _c("app-layout-footer")
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "layout-overlay",
      on: { click: _vm.closeSidenav }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }