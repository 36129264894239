<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
  name: 'Layout',
  data () {
    return {
      loading: true
    }
  },
  methods: {
    overlay (switcher) {
      this.loading = !!switcher
    }
  }
}
</script>

<style type="scss" >
    .fe-body{
        width: 100vw;
        height: 100vh;
        /*overflow: hidden;*/
        overflow: auto;
    }

    .fe-overlay{
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
    }

    .fe-overlay > div{
        width: 100%;
        height: 100%;
    }

    .fe-content{
        width: 100%;
        height: 100%;
        z-index: 100;
        overflow: auto;
    }
</style>
