import router, { routerPrefix } from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { getToken } from '@/utils/auth'
import { getPageTitle } from '@/utils'
import { getFormIco } from '@/api/frontend/form'
import VeeValidate from 'vee-validate'

NProgress.configure({ showSpinner: false })

const whiteList = [routerPrefix.admin + '/login', routerPrefix.admin + '/callback/rsvp']

router.beforeEach(async (to, form, next) => {
  if (to.path === '/youthacademy/onlinereservation/zh') {
    router.push({ name: 'Frontend_Form_School', query: { id: process.env.VUE_APP_FORM_ID, language: 'zh' } })
    next()
    return
  } else if (to.path === '/youthacademy/onlinereservation/pt') {
    router.push({ name: 'Frontend_Form_School', query: { id: process.env.VUE_APP_FORM_ID, language: 'pt' } })
    next()
    return
  } else if (to.path === '/youthacademy/onlinereservation/en') {
    router.push({ name: 'Frontend_Form_School', query: { id: process.env.VUE_APP_FORM_ID, language: 'en' } })
    next()
    return
  }
  if (to.path.substr(0, routerPrefix.admin.length) !== routerPrefix.admin && to.path.substr(0, routerPrefix.frontend.length) !== routerPrefix.frontend) {
    next(routerPrefix.frontend + to.path)
    return ''
  }

  if (to.path.substr(0, routerPrefix.frontend.length) === routerPrefix.frontend) {
    if (to.path === `${process.env.VUE_APP_URL_PREFIX}/public/form` || to.path === `${process.env.VUE_APP_URL_PREFIX}/public/form_school`) {
      if (to.query.language) {
        await store.dispatch('app/setLanguage', to.query.language)
        VeeValidate.Validator.localize(to.query.language)
      }
      let response = await getFormIco(to.query)
      let { data } = response

      // 请求失败
      if (data.code === 0) {
        const icoUrl = data.data.favIcon

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        if (link.href !== icoUrl) {
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'
          link.href = icoUrl
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        // if (data.data.defaultLang) {
        //   await store.dispatch('app/setLanguage', data.data.defaultLang)
        //   VeeValidate.Validator.localize(data.data.defaultLang)
        // }
      } else if (data.code === -9998) {
        router.push({ path: `${process.env.VUE_APP_URL_PREFIX}/public/error` })
      }
      // if (to.query.language) {
      //   await store.dispatch('app/setLanguage', to.query.language)
      //   VeeValidate.Validator.localize(to.query.language)
      // }
    }

    if (to.matched.length === 0) {
      form.name ? next({
        name: form.name
      }) : next(routerPrefix.frontend + '/error')
    } else {
      next()
    }
    return ''
  }

  NProgress.start()
  document.title = getPageTitle(to.meta.title)

  // Token
  const hasToken = getToken()

  if (hasToken) { // 存在Token
    if (to.path === routerPrefix.admin + '/login') {
      next({ path: routerPrefix.admin })
      NProgress.done()
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          const { authorities } = await store.dispatch('user/getInfo')
          const accessRoutes = await store.dispatch('permission/generateRoutes', authorities)
          // dynamically add accessible routes
          router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          await store.dispatch('user/resetToken')
          next(`${routerPrefix.admin}/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // const { roles } = await store.dispatch('user/getInfo')
      // const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
      // router.addRoutes(accessRoutes)
      next()
    } else {
      next(`${routerPrefix.admin}/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
