import Vue from 'vue'
import i18n from './common'

import App from './App.vue'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/en' // lang i18n
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import Notifications from 'vue-notification'
import globals from './globals'
import Popper from 'popper.js'
import { Vuelidate } from 'vuelidate'
import { DatePicker, Select, Option, Upload, InputNumber, TimePicker, Dialog, Table, TableColumn, Tabs, TabPane, Tag, Icon, Input, Drawer, Progress, Collapse, CollapseItem, Button } from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
const isProduction = process.env.NODE_ENV === 'production'

locale.use(lang)

Vue.use(VueClipboard)
Vue.use(VueAnalytics, {
  id: ['UA-169020679-2', 'UA-169020679-5'].concat(process.env.VUE_APP_GA_ID.split(','))
  // id: ['UA-169020679-2'] staging
})

Vue.use(BootstrapVue)
Vue.use(VeeValidate, {
  events: 'change|blur',
  fieldsBagName: 'veeFields'
})
VeeValidate.Validator.localize(i18n.locale)
VeeValidate.Validator.localize({
  en: {
    messages: {
      required: 'This column is required.',
      // email: 'The field must be a valid email',
      email: (_, value) => `${value}`,
      // min: (_, value) => `請輸入${value}位數字號碼 Please fill-in ${value}-digits number`
      min: (_, value) => i18n.t('VALIDATE_MOBILE'),
      max: (_, value) => '字數限制為256en'
    }
  },
  pt: {
    messages: {
      required: 'Campo obrigatório',
      // email: 'The field must be a valid email',
      email: (_, value) => `${value}`,
      // min: (_, value) => `請輸入${value}位數字號碼 Please fill-in ${value}-digits number`
      min: (_, value) => i18n.t('VALIDATE_MOBILE'),
      max: (_, value) => '字数限制为256pt'
    }
  },
  zh: {
    messages: {
      required: '必須填寫',
      // email: 'The field must be a valid email',
      email: (_, value) => `${value}`,
      // min: (_, value) => `請輸入${value}位數字號碼 Please fill-in ${value}-digits number`
      min: (_, value) => i18n.t('VALIDATE_MOBILE'),
      max: (_, value) => '字數限制為256'
    }
  },
  zh_CN: {
    messages: {
      required: '必須填写',
      // email: 'The field must be a valid email',
      email: (_, value) => `${value}`,
      // min: (_, value) => `請輸入${value}位數字號碼 Please fill-in ${value}-digits number`
      min: (_, value) => i18n.t('VALIDATE_MOBILE'),
      max: (_, value) => '字数限制为256'
    }
  }
})
Vue.use(Notifications)
Vue.use(Vuelidate)
Vue.use(DatePicker)
Vue.use(Drawer)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(InputNumber)
Vue.use(TimePicker)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Progress)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Button)

// Global RTL flag
Vue.mixin({
  data: globals,
  methods: {
    isEmpty(obj) {
      return !obj || Object.keys(obj).length === 0
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  performance: !isProduction,
  data: {
    sub: false
  }
}).$mount('#app')
