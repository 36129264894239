import { asyncRoutes, constantRoutes, routerPrefix } from '@/router'
import Layout from '@/layout/Layout'

export const types = {
  SET_ROUTERS: 'set_routers'
}

function hasPermission (roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.indexOf(role) >= 0)
  } else {
    return true
  }
}

function getConfigRoutes() {
  var configAsyncRoutes = []
  if (window.siteGlobalConfig.adminRoutesNew && window.siteGlobalConfig.adminRoutesNew.length > 0) {
    window.siteGlobalConfig.adminRoutesNew.forEach(function(item, index) {
      var childrenRoutes = []
      if (item.children && item.children.length > 0) {
        item.children.forEach(function(children) {
          childrenRoutes.push({
            path: children.path,
            component: () => import(`@/views${children.viewPath}`),
            name: children.name,
            meta: children.meta,
            hidden: children.hidden
          })
        })
      }
      configAsyncRoutes.push({
        path: routerPrefix.admin + item.path,
        component: Layout,
        hidden: item.hidden,
        meta: item.meta,
        children: childrenRoutes
      })
    })
    configAsyncRoutes.push({ path: '*', redirect: '/404', hidden: true })
  }
  return configAsyncRoutes || asyncRoutes
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes (routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  [types.SET_ROUTERS] (state, routes) {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes ({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes
      var actualAsyncRoutes = getConfigRoutes()
      if (roles.includes('ROLE_ADMIN')) {
        accessedRoutes = actualAsyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(actualAsyncRoutes, roles)
      }
      commit(types.SET_ROUTERS, accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
