export default {
  upload: _ => {
    return {
      code: 0,
      data: {
        url: 'http://localhost:8080/img/avatars/1.png',
        name: '测试图片'
      }
    }
  }
}
