import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { getLanguage } from '@/locales/index'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.params === undefined
      ? (config['params'] = {
        language: `${getLanguage()}`
      })
      : (config.params['language'] = `${getLanguage()}`)

    config.params['mode'] = process.env.VUE_APP_MODE || 'general'

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data

    if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    const res = error.response.data
    console.log(res)
    if (res.fieldErrors !== undefined && res.fieldErrors.length > 0) {
      res.fieldErrors.forEach(function(item, index) {
        Vue.notify({
          group: 'notifications-default',
          type: 'bg-warning text-dark',
          title: 'Warning',
          text: item.message
        })
      })
    } else {
      Vue.notify({
        group: 'notifications-default',
        type: 'bg-danger text-white',
        title: 'Error',
        text: error.message
      })
    }
    return Promise.reject(error)
  }
)

export default service
