const state = {
  template: '',
  finishedData: {}
}

const mutations = {
  SET_TEMPLATE: (state, template) => {
    state.template = template
  },
  SET_FINISHED_DATA: (state, template) => {
    state.finishedData = template
  }
}

const actions = {
  setTemplate (context, template) {
    context.commit('SET_TEMPLATE', template)
  },
  setFinishedData (context, data) {
    context.commit('SET_FINISHED_DATA', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
