module.exports = {
  title: '',
  successAlert: 'bg-success text-white',
  errorAlert: 'bg-danger text-white',
  warningAlert: 'bg-warning text-dark',
  languages: [
    { label: '繁體', value: 'zh' },
    { label: 'English', value: 'en' },
    { label: 'Português', value: 'pt' },
    { label: '简体', value: 'zh_CN' }
  ]
}
