// import zhHantLocale from './zhHant'
// import enLocale from './en'

// var locale = {
//   zhHant: {
//     ...zhHantLocale
//   },
//   en: {
//     ...enLocale
//   }
// }

// export default locale
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementPtLocale from 'element-ui/lib/locale/lang/pt' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
// import elementEsLocale from 'element-ui/lib/locale/lang/es'// element-ui lang
// import elementJaLocale from 'element-ui/lib/locale/lang/ja'// element-ui lang
import enLocale from './en'
import ptLocale from './pt'
import zhLocale from './zh_TW'
import zhCnLocale from './zh_CN'
// import esLocale from './es'
// import jaLocale from './ja'

Vue.use(VueI18n)

const messages = {
  // en: {
  //   ...enLocale,
  //   ...elementEnLocale
  // },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  // zh_TW: {
  //   ...zhLocale,
  //   ...elementZhLocale
  // },
  zh_CN: {
    ...zhCnLocale,
    ...elementZhLocale
  },
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  pt: {
    ...ptLocale,
    ...elementPtLocale
  }
  // es: {
  //   ...esLocale,
  //   ...elementEsLocale
  // },
  // ja: {
  //   ...jaLocale,
  //   ...elementJaLocale
  // }
}
export function getLanguage () {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  // const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  // const locales = Object.keys(messages)
  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return locale
  //   }
  // }
  // console.log(process.env)
  return process.env.VUE_APP_DEFAULT_LANGUAGE || 'zh'
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n
