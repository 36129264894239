import request from '@/utils/request'

/**
 * 获取当前用户信息
 * @param {*} params
 */
export function getUserInfo (params = {}) {
  return request({
    url: '/account',
    method: 'get',
    params: params
  })
}

/**
 * 獲取用戶信息
 * @param {*} params
 */
export function getProfile (params = {}) {
  return request({
    url: '/account',
    method: 'get',
    params: params
  })
}

/**
 * 更新个人资料
 * @param {*} params
 */
export function updateProfile (params = {}) {
  return request({
    url: '/user/update_profile',
    method: 'post',
    data: params
  })
}

/**
 * 更新密码
 * @param {*} params
 */
export function updatePassword (params = {}) {
  return request({
    url: '/account/change-password',
    method: 'post',
    data: params
  })
}

/**
 * 獲取管理員用戶列表
 * @param {*} params
 */
export function getUserList (params = {}) {
  return request({
    url: '/users',
    method: 'get',
    params: params
  })
}

/**
 * 创建管理員用戶
 * @param {*} params
 */
export function createUser (params = {}) {
  return request({
    url: '/users',
    method: 'post',
    data: params
  })
}

/**
 * 更新管理員用戶
 * @param {*} params
 */
export function updateUser (params = {}) {
  return request({
    url: `/users`,
    method: 'put',
    data: params
  })
}

/**
 * 删除管理員用戶
 * @param {*} params
 */
export function deleteUser (login, params = {}) {
  return request({
    url: `/users/${login}`,
    method: 'delete',
    data: params
  })
}

export function getAuthorities (params = {}) {
  return request({
    url: `/users/authorities`,
    method: 'get',
    params
  })
}
