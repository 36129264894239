import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/Layout'
import FrontendLayout from '@/layout/frontend/Layout'
import SchoolFrontendLayout from '@/layout/frontend/SchoolLayout'
import CommonLayout from '@/layout/common/Layout'

Vue.use(Router)

/**
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

export const routerPrefix = {
  admin: `${process.env.VUE_APP_URL_PREFIX}/admintools`,
  frontend: `${process.env.VUE_APP_URL_PREFIX}/public`
}

export const constantFrontendRoutes = [
  {
    path: routerPrefix.frontend,
    component: FrontendLayout,
    meta: { title: 'FRONTEND_TITLE' },
    hidden: true,
    children: [
      {
        path: '/',
        component: () => import('@/views/frontend/error'),
        name: 'Frontend_Index',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      // {
      //   path: 'form_school',
      //   component: () => import('@/views/frontend/form_school'),
      //   name: 'Frontend_Form_School',
      //   meta: { title: 'FRONTEND_TITLE_FORM' }
      // },
      // {
      //   path: 'search',
      //   component: () => import('@/views/frontend/search'),
      //   name: 'Frontend_Search',
      //   meta: { title: 'FRONTEND_TITLE_FORM' }
      // },
      // {
      //   path: 'form',
      //   component: () => import('@/views/frontend/form'),
      //   name: 'Frontend_Form',
      //   meta: { title: 'FRONTEND_TITLE_FORM' }
      // },
      {
        path: 'form2',
        component: () => import('@/views/frontend/form2'),
        name: 'Frontend_Form',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'message',
        component: () => import('@/views/frontend/message'),
        name: 'message',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'staff',
        component: () => import('@/views/frontend/staff'),
        name: 'Frontend_Staff',
        meta: { title: 'FRONTEND_TITLE_STAFF' }
      },
      {
        path: 'error',
        component: () => import('@/views/frontend/error'),
        name: 'Frontend_Error',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'qrcode',
        component: () => import('@/views/frontend/qrcode'),
        name: 'Frontend_Qrcode',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      }
    ]
  },
  {
    path: routerPrefix.frontend,
    component: SchoolFrontendLayout,
    meta: { title: 'FRONTEND_TITLE' },
    hidden: true,
    children: [
      {
        path: 'form',
        component: () => import('@/views/frontend/form_school'),
        name: 'Frontend_Form',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'form_school',
        component: () => import('@/views/frontend/form_school'),
        name: 'Frontend_Form_School',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'search',
        component: () => import('@/views/frontend/search'),
        name: 'Frontend_Search',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'finished',
        component: () => import('@/views/frontend/finished'),
        name: 'Frontend_Finished',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'finished_survey',
        component: () => import('@/views/frontend/finished_survey'),
        name: 'Frontend_Survey_Finished',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      }
    ]
  }
]

export const constantAdminRoutes = [
  {
    path: routerPrefix.admin + '/login',
    component: () => import('@/views/login/index'),
    meta: { title: 'TITLE_LOGIN' },
    hidden: true
  },
  {
    path: routerPrefix.admin + '/callback/rsvp',
    component: () => import('@/views/login/callback'),
    meta: { title: 'TITLE_LOGIN' },
    hidden: true
  },
  {
    path: routerPrefix.admin + '/callback/gitlab',
    component: () => import('@/views/login/callback'),
    meta: { title: 'TITLE_LOGIN' },
    hidden: true
  },
  {
    path: routerPrefix.admin,
    component: Layout,
    redirect: routerPrefix.admin + window.siteGlobalConfig.adminRoutes.dashboard.redirectPath,
    name: 'admintools',
    hidden: window.siteGlobalConfig.adminRoutes.dashboard.hidden,
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: 'TITLE_DASHBOARD', icon: 'ion ion-md-speedometer' }
      }
    ]
  },
  {
    path: routerPrefix.admin + '/setting',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        name: 'Profile',
        component: () => import('@/views/setting/index'),
        meta: { title: 'TITLE_PROFILE' },
        hidden: true
      },
      {
        path: 'system',
        name: 'System',
        component: () => import('@/views/setting/system'),
        meta: { title: 'TITLE_SYSTEM_SETTING', icon: 'ion ion-md-hammer' }
      }
    ]
  }
]

export const constantRoutes = [
  {
    path: routerPrefix.frontend + '/account',
    component: CommonLayout,
    hidden: true,
    children: [
      {
        path: 'activate',
        name: 'Activate',
        component: () => import('@/views/login/activate'),
        meta: { title: 'TITLE_PROFILE' }
      },
      {
        path: 'reset',
        name: 'Reset',
        component: () => import('@/views/login/reset'),
        meta: { title: 'TITLE_SYSTEM_SETTING', icon: 'ion ion-md-hammer' }
      }
    ]
  },
  {
    path: routerPrefix.frontend,
    component: CommonLayout,
    hidden: true,
    children: [
      {
        path: 'info',
        component: () => import('@/views/frontend/info_dssopt'),
        name: 'info',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'qrcode',
        component: () => import('@/views/frontend/qrcode'),
        name: 'qrcode',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      },
      {
        path: 'preview',
        component: () => import('@/views/frontend/preview'),
        name: 'preview',
        meta: { title: 'FRONTEND_TITLE_FORM' }
      }
    ]
  }

].concat(constantFrontendRoutes, constantAdminRoutes)

export const asyncRoutes = [
  {
    path: routerPrefix.admin + '/event',
    component: Layout,
    hidden: !window.siteGlobalConfig.adminRoutes.event.useOutSide,
    children: [
      {
        path: 'index',
        component: () => import('@/views/event/index'),
        name: 'EventLv',
        meta: { title: 'TITLE_EVENT_MANAGER', icon: 'ion ion-md-people' }
      }
    ]
  },
  {
    path: routerPrefix.admin + '/campaign',
    component: Layout,
    meta: { title: 'TITLE_CAMPAIGN_MANAGER', icon: 'ion ion-md-people', roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_DSSOPT_VIEWER', 'ROLE_CAMPAIGN_EDITOR', 'ROLE_CAMPAIGN_VIEWER', 'ROLE_GUEST_EDITOR', 'ROLE_GUEST_VIEWER'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/campaign/index'),
        name: 'Campaign',
        hidden: window.siteGlobalConfig.adminRoutes.campaign.hidden,
        meta: { title: 'TITLE_CAMPAIGN_MANAGER' }
      },
      {
        path: 'event',
        component: () => import('@/views/event/index'),
        name: 'Event',
        hidden: window.siteGlobalConfig.adminRoutes.event.hidden,
        meta: { title: 'TITLE_EVENT_MANAGER' }
      },
      {
        path: 'form',
        component: () => import('@/views/form/index'),
        name: 'Form',
        hidden: window.siteGlobalConfig.adminRoutes.form.hidden,
        meta: { title: 'TITLE_FORM_MANAGER' }
      },
      {
        path: 'invitation',
        component: () => import('@/views/invitation/index'),
        name: 'Invitation',
        hidden: window.siteGlobalConfig.adminRoutes.invitation.hidden,
        meta: { title: 'TITLE_INVITATION_MANAGER', roles: ['ROLE_ADMIN', 'ROLE_VIEWER'] }
      },
      {
        path: 'registration',
        component: () => import('@/views/registration/index'),
        name: 'Registration',
        hidden: window.siteGlobalConfig.adminRoutes.registration.hidden,
        meta: { title: 'TITLE_REGISTRATION_MANAGER', roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_USER'] }
      }
    ]
  },
  // {
  //   path: routerPrefix.admin + '/template',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/template/index'),
  //       name: 'Template',
  //       meta: { title: 'TITLE_TEMPLATE_MANAGER', icon: 'ion ion-md-people', roles: ['ROLE_ADMIN', 'ROLE_VIEWER'] }
  //     }
  //   ]
  // },
  // {
  //   path: routerPrefix.admin + '/form',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/form/index'),
  //       name: 'Form',
  //       meta: { title: 'TITLE_FORM_MANAGER', icon: 'ion ion-md-people' }
  //     }
  //   ]
  // },
  {
    path: routerPrefix.admin + '/guest-group',
    component: Layout,
    hidden: window.siteGlobalConfig.adminRoutes.guestGroup.hidden,
    meta: { roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_DSSOPT_VIEWER', 'ROLE_CAMPAIGN_EDITOR', 'ROLE_CAMPAIGN_VIEWER', 'ROLE_GUEST_EDITOR', 'ROLE_GUEST_VIEWER'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/guest-group/index'),
        name: 'GuestGroup',
        meta: { title: 'TITLE_GUEST_GROUP_MANAGER', icon: 'ion ion-md-people' }
      }
    ]
  },
  {
    path: routerPrefix.admin + '/guest',
    component: Layout,
    hidden: window.siteGlobalConfig.adminRoutes.guest.hidden,
    meta: { roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_DSSOPT_VIEWER', 'ROLE_CAMPAIGN_EDITOR', 'ROLE_CAMPAIGN_VIEWER', 'ROLE_GUEST_EDITOR', 'ROLE_GUEST_VIEWER'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/guest/index'),
        name: 'Guest',
        meta: { title: 'TITLE_GUEST_MANAGER', icon: 'ion ion-md-people' }
      }
    ]
  },
  // {
  //   path: routerPrefix.admin + '/invitation',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/invitation/index'),
  //       name: 'Invitation',
  //       meta: { title: 'TITLE_INVITATION_MANAGER', icon: 'ion ion-md-people', roles: ['ROLE_ADMIN', 'ROLE_VIEWER'] }
  //     }
  //   ]
  // },
  // {
  //   path: routerPrefix.admin + '/registration',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/registration/index'),
  //       name: 'Registration',
  //       meta: { title: 'TITLE_REGISTRATION_MANAGER', icon: 'ion ion-md-people', roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_USER'] }
  //     }
  //   ]
  // },
  {
    path: routerPrefix.admin + '/form-answer',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/form-answer/index'),
        name: 'Form Answer',
        meta: { title: 'TITLE_FORM_ANSWER_MANAGER', icon: 'ion ion-md-people', roles: ['ROLE_ADMIN', 'ROLE_VIEWER'] }
      }
    ]
  },
  {
    path: routerPrefix.admin + '/staff',
    component: Layout,
    hidden: window.siteGlobalConfig.adminRoutes.staff.hidden,
    meta: { roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_DSSOPT_VIEWER', 'ROLE_CAMPAIGN_EDITOR', 'ROLE_CAMPAIGN_VIEWER', 'ROLE_GUEST_EDITOR', 'ROLE_GUEST_VIEWER'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/staff/index'),
        name: 'Staff',
        meta: { title: 'TITLE_STAFF_MANAGER', icon: 'ion ion-md-people' }
      }
    ]
  },
  {
    path: routerPrefix.admin + '/user',
    component: Layout,
    hidden: window.siteGlobalConfig.adminRoutes.user.hidden,
    meta: { roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_DSSOPT_VIEWER', 'ROLE_CAMPAIGN_EDITOR', 'ROLE_CAMPAIGN_VIEWER', 'ROLE_GUEST_EDITOR', 'ROLE_GUEST_VIEWER'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/user/index'),
        name: 'User',
        meta: { title: 'TITLE_USER_MANAGER', icon: 'ion ion-md-people', roles: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: routerPrefix.admin + '/app-setting',
    component: Layout,
    hidden: window.siteGlobalConfig.adminRoutes.appSetting.hidden,
    meta: { roles: ['ROLE_ADMIN', 'ROLE_VIEWER', 'ROLE_DSSOPT_VIEWER', 'ROLE_CAMPAIGN_EDITOR', 'ROLE_CAMPAIGN_VIEWER', 'ROLE_GUEST_EDITOR', 'ROLE_GUEST_VIEWER'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/app-setting/index'),
        name: 'AppSetting',
        meta: { title: 'TITLE_APP_SETTING', icon: 'ion ion-md-people', roles: ['ROLE_ADMIN'] }
      }
    ]
  },

  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  base: '/',
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// eslint-disable-next-line space-before-function-paren
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
