export default {
  getDashboardInfo: _ => {
    return {
      code: 0,
      data: {
        statistics: {
          visit: 2362,
          page: 23,
          messageTemp: 12,
          user: 10
        }
      }
    }
  }
}
